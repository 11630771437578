<template>
  <div v-if="exists" class="d-flex">
    <item-broadcast
      v-if="sStatus"
      :message="$t(`status.contract.${sStatus}`)"
      :subtitle="$t('status')"
      hide-progress
    />
    <item-broadcast
      v-if="sLatestInvoiceDate"
      :message="sLatestInvoiceDate"
      :subtitle="$t('last.invoice.date')"
      hide-progress
    />
    <item-broadcast
      v-if="sLastDate"
      :message="sLastDate"
      :subtitle="$t('contract.last.date')"
      hide-progress
    />
    <item-broadcast
      v-if="sNextDate"
      :message="sNextDate"
      :subtitle="$t('contract.next.date')"
      hide-progress
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { Contract, InvoiceData } from "@planetadeleste/vue-mc-gw";
import dayjs from "dayjs";
import ItemBroadcast from "@/components/common/broadcast/ItemBroadcast.vue";

@Component({
  components: { ItemBroadcast },
})
export default class ContractInfo extends Vue {
  @Prop(Object) readonly item!: Contract;

  get exists() {
    return !!this.item.id;
  }

  get obNextDate() {
    if (!this.exists) {
      return undefined;
    }

    const sDate = this.item.get("next_date") as string | undefined;

    return sDate ? dayjs(sDate) : undefined;
  }

  get sNextDate() {
    return this.obNextDate?.format("DD/MM/YYYY");
  }

  get obLastDate() {
    if (!this.exists) {
      return undefined;
    }

    const sDate = this.item.get("last_date") as string | undefined;

    return sDate ? dayjs(sDate) : undefined;
  }

  get sLastDate() {
    return this.obLastDate?.format("DD/MM/YYYY");
  }

  get sStatus() {
    return this.item.get("status");
  }

  get obLatestInvoice(): InvoiceData | undefined {
    return this.item.get("latest_invoice");
  }

  get sLatestInvoiceDate() {
    return this.obLatestInvoice
      ? dayjs(this.obLatestInvoice.created_at).format("DD/MM/YYYY")
      : undefined;
  }
}
</script>
