<template>
  <v-form v-if="obModel" class="fill-height" @submit.prevent>
    <form-observer
      :loading="isLoading"
      @cancel="cancel"
      @save="save"
      :dirty="isDirty"
    >
      <template #top-actions-left>
        <v-toolbar-title v-text="$t('contracts')" />
      </template>

      <template #top-actions>
        <contract-info v-if="!!obModel.id" :item="obModel" />
      </template>

      <template #default="{ valid }">
        <settings v-model="obModel" :valid="valid" />
      </template>
    </form-observer>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ContractsMixin from "@/modules/contracts/mixins/ContractsMixin";
import Settings from "@/modules/contracts/components/tabs/Settings.vue";
import ContractInfo from "@/modules/contracts/components/ContractInfo.vue";

@Component({
  components: {
    ContractInfo,
    Settings,
  },
})
export default class ContractsForm extends Mixins(ContractsMixin) {}
</script>
